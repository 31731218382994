/** @jsx jsx */
import React from 'react'
import Img from 'gatsby-image'

import { Footer, jsx } from 'theme-ui'

import Branding from './branding'
import Navigation from './navigation'

export default ({ data }) => (
  <Footer>
    <div>This is a footer</div>
    <div>Copyright &copy; Cookie Tempura 2019 - All rights reserved</div>
  </Footer>
)
