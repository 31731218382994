/** @jsx jsx */
import React from 'react'
import { Link } from 'gatsby'

import CookieIcon from '../assets/icon/ic_cookie.svg'
import ShrimpIcon from '../assets/icon/ic_shrimp.svg'

import { jsx, useColorMode } from 'theme-ui'

export default props => {
  const [colorMode, setColorMode] = useColorMode()
  return (
    <nav role="navigation">
      <ul
        sx={{
          listStyleType: `none`,
          li: {
            display: `inline-block`,
            pl: `4`,
          },
        }}
      >
        <li>
          <Link to="/about/">About</Link>
        </li>
        <li>
          <Link to="/news/">News</Link>
        </li>
        <li>
          <Link to="/countries/">Countries</Link>
        </li>
        <li>
          <Link to="/locations/">Locations</Link>
        </li>
        <li>
          <div
            onClick={e => {
              setColorMode(colorMode === 'cookie' ? 'tempura' : 'cookie')
            }}
          >
            {colorMode === 'cookie' ? 'Tempura' : 'Cookie'}
          </div>
        </li>
      </ul>
    </nav>
  )
}
