/** @jsx jsx */
import { jsx } from 'theme-ui'

import React from 'react'
import { Link } from 'gatsby'

export default () => (
  <>
    <Link
      sx={{
        fontWeight: `bold`,
      }}
      to="/"
    >
      Cookie Tempura
    </Link>
  </>
)
