/** @jsx jsx */
import React from 'react'
import Img from 'gatsby-image'

import { Header, jsx } from 'theme-ui'

import Branding from './branding'
import Navigation from './navigation'

export default ({ data }) => (
  <Header>
    <Branding />
    <Navigation />
  </Header>
)
