/** @jsx jsx */
import React from 'react'
import { Link } from 'gatsby'
import { Styled, Main, ColorMode, jsx } from 'theme-ui'

import Container from './container'
import Header from './header'
import Footer from './footer'

class Template extends React.Component {
  render() {
    const { location, children } = this.props
    let header

    let rootPath = `/`
    if (typeof __PREFIX_PATHS__ !== `undefined` && __PREFIX_PATHS__) {
      rootPath = __PATH_PREFIX__ + `/`
    }

    return (
      <Styled.root>
        <Container>
          <Header />
          <Main>{children}</Main>
          <Footer />
        </Container>
      </Styled.root>
    )
  }
}

export default Template
